<template>
  <div class="container-xxl py-24">
    <AppBreadcrumbs
      :links="[
        {
          text: 'Кабинет',
          alias: '/applicant/interviews'
        },
        {
          text: 'Доп. интервью'
        }
      ]"
    />
    <div
      class="mx-auto"
      style="max-width: 1000px"
    >
      <VideoRecord
        :questions="questions"
        :is-show="isShow"
        is-interview
        @set-record-blob="setRecordBlob"
        @webm-supported="setWebmSupported"
      >
        <template #nextStep>
          <AppButton
            class="fw-bold"
            @click="publication"
          >
            Опубликовать
          </AppButton>
        </template>
      </VideoRecord>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QuestionRecord } from "~/entities/QuestionRecord";
import { useApiRecordUpload } from "~/composables/api/RecordUpload";
import type { UploadVideoData } from "~/entities/UploadVideoData";

const { $toast } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const apiRecordUpload = useApiRecordUpload();

//-----VARIABLES-----\\
let isWebmSupported = true;

//-----STATE-----\\
const id = ref<string>(route.params.response_id);
const questions = ref<QuestionRecord[]>([]);
const uploadVideoList = useState<UploadVideoData[]>('uploadVideoList');
const isShow = ref(false);

//-----ASYNC-----\\
const { data } = await mainFetch('responses/' + id.value);//TODO: ПРОВЕРКА
questions.value = data.value.response.questions.map((val): QuestionRecord => {
  return {
    id: val.id,
    question: val.question,
    hint: val.hint,
    position: val.position,
    type: val.type,
    time: val.time,
    isRequired: val.isRequired,
    answer: val.answer,
    recordBlob: null,
  };
});

//-----METHODS-----\\
async function publication() {
  if (questions.value.findIndex(value => value.isRequired === true && value.recordBlob === null) !== -1) {
    return;
  }

  const newVideos = questions.value.filter(value => value.recordBlob !== null);
  const isUploadVideo = newVideos.length !== 0;

  let isError = false;
  let recordToken = undefined;
  await mainFetch(`/responses/${id.value}/answers`, {
    method: 'POST',
    body: {
      records: await Promise.all(questions.value.filter(item => item.answer !== null || item.recordBlob !== null).map(async (item) => {
        return {
          id: item.answer?.id??null,
          questionId: item.id,
          hash: item.recordBlob === null ? null : await apiRecordUpload.getHash(item.recordBlob)
        };
      })),
    },
  }).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      isError = true;
      $toast.error(data.error.clientMessage??data.error.message);
      return;
    }

    if (isUploadVideo) {
      recordToken = response.recordToken;
      (response.records as object[]).forEach(value => {
        for (let i = 0; i < newVideos.length; i++) {
          if (value.questionId !== newVideos[i].id) {
            continue;
          }

          newVideos[i].answer = {
            id: value.answerId,
            src: null,
            isAssembled: false,
            created: Math.ceil((new Date()).getTime() / 1000),
          };
          break;
        }
      });
    }
  });

  if (isError) {
    $toast.error('Ошибка');
    return;
  }

  const errVideoCount = newVideos.filter(value => value.answer === null).length;
  if (isUploadVideo) {
    if (errVideoCount !== 0) {
      $toast.error(`${errVideoCount}/${newVideos.length} видео не могут быть сохранены`);
    }

    if (errVideoCount !== newVideos.length) {
      for (let i = 0; i < newVideos.length; i++) {
        if (newVideos[0].answer === null) {
          continue;
        }

        uploadVideoList.value.push({
          token: recordToken,
          answerId: newVideos[i].answer!.id,
          isVacancy: false,
          file: newVideos[i].recordBlob!,
          isWebm: isWebmSupported,
          upload: {
            status: 0,
            size: {
              loaded: null,
              total: null,
            },
          },
        });
      }
    }
  }

  $toast.success(!isUploadVideo ? 'Успешно' : 'Успешно. Осталось лишь дождаться окончания загрузки видео.');

  router.push('/applicant/interviews');
}
function setRecordBlob(index: number, value: Blob|null) {
  questions.value[index].recordBlob = value;
  questions.value[index].answer = null;
}
function setWebmSupported(val: boolean) {
  isWebmSupported = val;
}

onMounted(() => {
  isShow.value = true;
});
</script>

<style lang="scss">
//@import 'vue-toastification/src/scss/index';
</style>